import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { Theme, TextField, Button } from '@material-ui/core';
import { styles } from './Cache.styles';
import { inject, observer } from 'mobx-react';
import { SyncTroubleshootStore } from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { IClasses, UIStore, regex } from '@wings-shared/core';
import { AlertStore } from '@uvgo-shared/alert';
import { AxiosError } from 'axios';
import { useUnsubscribe } from '@wings-shared/hooks';
import { AuthStore, useRoles } from '@wings-shared/security';

interface Props {
  syncTroubleshootStore: SyncTroubleshootStore;
}

const Cache: FC<Props> = ({ ...props }: Props) => {

  const [ userId, setUserId ] = useState<string>('');
  const [ email, setEmail ] = useState<string>('');
  const _syncTroubleshootStore = props.syncTroubleshootStore as SyncTroubleshootStore;
  const unsubscribe = useUnsubscribe();
  const classes: Record<string, string> = styles();

  /* istanbul ignore next */
  const isUserIdValid = (): boolean => {
    if(userId === '' || userId === null){
      return false;
    }
    return regex.numberOnly.test(userId);
  }

  /* istanbul ignore next */
  const deleteTokenEnrichment = () => {
    UIStore.setPageLoader(true);
    _syncTroubleshootStore
      .deleteTokenEnrichment(userId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false)),
      )
      .subscribe(() => setUserId(''));
  }


  /* istanbul ignore next */
  const isEmailValid = (): boolean => {
    if (email === '' || email === null) {
      return false;
    }
    return regex.email.test(email);
  }

  /* istanbul ignore next */
  const cacheCleaning = () => {
    UIStore.setPageLoader(true);
    _syncTroubleshootStore
      .cacheCleaning(email)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false)),
      )
      .subscribe(
        (response: string) => {
          AlertStore.info(`Cache Cleaning for Email Id ${email} Successfully`);
        },
        (error: AxiosError) => AlertStore.critical(error.message)
      );
  }

  return (
    <div className={classes.scrollable}>
      <div className={classes.content}>
        <TextField
          label="Token Enrichment Reset"
          placeholder="Enter user Id"
          className={classes.usernameInput}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) => setUserId(target.value)}
          value={userId}
          error={!isUserIdValid() && Boolean(userId)}
          helperText={
            !isUserIdValid() && Boolean(userId) ? 'Enter user Id only' : ''
          }
        />
        <Button
          color="primary"
          variant="contained"
          size="small"
          className={classes.btnSubmit}
          disabled={!isUserIdValid()}
          onClick={() => deleteTokenEnrichment()}
        >
          Submit
        </Button>
      </div>
      <div className={classes.content}>
        <TextField
          label="Clear CSD Products and Services"
          placeholder="Enter username"
          className={classes.usernameInput}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) => setEmail(target.value)}
          value={email}
          error={!isEmailValid() && Boolean(email)}
          helperText={
            !isEmailValid() && Boolean(email) ? 'Enter Email only' : ''
          }
        />
        <Button
          color="primary"
          variant="contained"
          size="small"
          className={classes.btnSubmit}
          disabled={!isEmailValid()}
          onClick={() => cacheCleaning()}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default inject('syncTroubleshootStore')(observer(Cache));
