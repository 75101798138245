import { IdNameModel, ISelectOption, modelProtection, Utilities } from '@wings-shared/core';
import { IAPIUserProfileRoleResponse } from '../Interfaces';
import { AttributesModel } from './Attributes.model';
import { CustomerModel } from './Customer.model';
import { SiteModel } from '../Models/Sites.model';

@modelProtection
export class UserProfileRolesModel extends IdNameModel {
    roleId: string = '';
    displayName: string = '';
    description: string = '';
    permissions: string[] = [];
    attributes: AttributesModel[] = [];
    customer?: CustomerModel | null;
    site?: SiteModel | null;
    customerNumber: string = '';
    applicationId: string = '';
    appServiceId: string = '';

    constructor(data?: Partial<UserProfileRolesModel>) {
      super();
      Object.assign(this, data);
      this.attributes = data?.attributes?.map(x => new AttributesModel(x)) || [];
      this.customer = data?.customer && new CustomerModel(data?.customer);
      this.site = data?.site && new SiteModel(data?.site);
      this.customerNumber = data?.attributes?.find(x => x.type === 'CustomerNumber')?.value ?? '';
    }

    static deserialize(role: IAPIUserProfileRoleResponse): UserProfileRolesModel {
      if (!role) {
        return new UserProfileRolesModel();
      }
      const data: Partial<UserProfileRolesModel> = {
        id: Utilities.getTempId(true),
        roleId: role.RoleId,
        name: role.Name,
        description: role.Description,
        displayName: role.DisplayName,
        permissions: role.Permissions,
        attributes: AttributesModel.deserializeList(role.Attributes),
        applicationId: role.ApplicationId,
        appServiceId: role.AppServiceId,
      };

      return new UserProfileRolesModel(data);
    }

    public serialize(): IAPIUserProfileRoleResponse { 
      return {
        RoleId: this.roleId,
        Name: this.name,
        DisplayName: this.displayName,
        Description: this.description,
        Permissions: this.permissions,
        ApplicationId: this.applicationId,
        AppServiceId: this.appServiceId,
        Attributes: this.attributes?.map((attributes: AttributesModel) => attributes.serialize()) || [],
      };
    }

    static deserializeList(roles: IAPIUserProfileRoleResponse[]): UserProfileRolesModel[] {
      return roles
        ? roles.map((role: IAPIUserProfileRoleResponse) => UserProfileRolesModel.deserialize(role))
        : [];
    }

    // required in auto complete
    public get label(): string {
      return this.name;
    }

    public get value(): string | number {
      return this.id;
    }
}