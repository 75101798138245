export const apiUrls = {
  user: '/api/v1/okta/users',
  userGroups: (id: string) => `api/v1/okta/users/${id}/groups`,
  removeGroup: (id: string, groupId: string) => `api/v1/okta/users/${id}/groups/${groupId}`,
  groups: '/api/v1/groups',
  groupsUsers: (id: string) => `api/v1/groups/${id}/users`,
  deleteGroup: (id: string) => `api/v1/groups/${id}`,
  migrate: 'user/v1/migrate',
  csdUser: 'user/v1/csd/users',
  mapCsdUser: '/api/v2/okta/users/csd/mapping',
  activate: (id: string) => `/api/v1/okta/users/${id}/activate`,
  deactivate: (id: string) => `/api/v1/okta/users/${id}/deactivate`,
  update: '/api/v1/okta/users',
  enqueueImportJob: 'user/api/v1/jobs/sync/csdokta',
  updateEmail: 'user/v1/users/email',
  resendActivationCode: 'user/v1/profiles/resendactivationcode',
  getUserData: (userId: string) => `/api/v2/okta/users/${userId}`,
  deleteUser: (userId: string) => `/api/v1/okta/users/${userId}`,
  unlockUser: (userId: string) => `/api/v1/okta/users/${userId}/unlock`,
  lookup: 'api/v2/okta/users/lookup',
  expirePassword: (userId: string) => `/api/v1/okta/users/${userId}/expirepassword`,
  userToGroup: (userId: string) => `api/v1/okta/users/${userId}/groups`,
  removeUserFromGroup: (userId: string, groupId: string) => `api/v1/okta/users/${userId}/groups/${groupId}`,
  exportUsers: 'api/v1/users/export',
  federationMapping: '/user/v1/idps/mappings',
  tokens: '/api/v1/tokens',
  sessions: 'api/v1/sessions',
  sessionviolations: 'api/v1/sessionviolations',
  deleteSessionViolations: (userId: string) => `/api/v1/sessionviolations/${userId}`,
  updateCSDProfile: '/user/v1/users',
  triggerRefreshUsersJob: '/api/v1/jobs/sync/users',
  logs: '/api/v1/logs',
  userProfiles: '/api/v3/users',
  getUsersCache: '/api/v3/autocomplete/users',
  customers: '/api/v1/customers',
  services: '/api/v1/services',
  triggerRefreshCustomers: '/api/v1/jobs/sync/customers',
  triggerSyncUserPreferences: '/api/v1/jobs/sync/user-preferences',
  triggerFactsLoader: 'api/v1/facts/loader',
  deleteTokenEnrichment: '/user/v1/cache/selected/keys',
  resyncUser: '/api/v1/sync/users',
  userPreferences: (userGuid: string) => `/api/v3/users/${userGuid}/preferences`,
  getOracleUsers: '/user/v1/oracle/users',
  userFacts: (actorId: string, predicateFilter: string) =>
    `/api/v1/facts/search?ActorType=User&ActorId=${actorId}&Predicate=${predicateFilter}`,
  checkAuthorize: (resourceType: string) => `/api/v1/resources/${resourceType}/authorize`,
  createNewUser: '/api/v2/okta/users',
  checkFactExplorer: '/api/v1/facts/search',
  customerUsers: 'api/v1/customers',
  factsCleanup: '/api/v1/facts/bulk',
  salesPersons: '/user/v1/salespersons',
  teamContacts: '/api/v1/CommonUtility/TeamContacts',
  cacheCleaning: '/api/v1/cache',
  applications: 'api/v1/applications',
  oktaApplications: 'api/v1/okta/applications',
  reactivate: (userId: string) => `/api/v1/okta/users/reactivate?userId=${userId}`,
  assignRoles: '/api/v1/jobs/assign-role',
  unassignRoles: '/api/v1/jobs/unassign-role',
};